import React from "react";
import PropTypes from "prop-types";
import { makeStyles, Typography, useTheme } from "@material-ui/core";
import { AppBar, Tabs, Tab, Box, Button, TextField } from "@material-ui/core";
import { lightTheme } from "../../Themes/LightTheme";
import { useAuth } from "react-oidc-context";
import { Link } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import { useDispatch } from "react-redux";
import { spocEmail } from "../../Utilities/Utility";
import { useForm, Controller } from "react-hook-form";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  loginTabs: {
    flexGrow: 1,
    backgroundColor: `rgba(255, 255, 255, 0.85)`,
    boxShadow: `0 8px 32px #CCCCCC`,
    borderRadius: "10px",
  },
}));

export default function LoginRegisterTabs() {
  const dispatch = useDispatch();
  const auth = useAuth();
  const classes = useStyles();
  const theme = useTheme();

  const [value, setValue] = React.useState(0);
  //const errMessage = useSelector((state) => state.errorMessageReducer);
  const { control, handleSubmit } = useForm();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  function myIDLogin() {
    dispatch(auth.signinRedirect);
  }

  const handleClickRegister = () => {
    alert("Yet to be developed");
  };

  return (
    <div className="ssiat-lr-block">
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          scrollButtons="auto"
        >
          <Tab label="MyID Login" {...a11yProps(0)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <span className="d-block">
            <h3 className="heading-margin-5tb-0lr">
              Siemens Employee Login{" "}
            </h3>
            <p>If you are a Siemens Employee, login via MyID</p>
          </span>
          <div className="d-flex jc-center mt-1">
            <Link to="/">
              <Button
                className="pt-button--hero myid-login-btn-block"
                id="btnMyIDLogin"
                onClick={myIDLogin}
              //onClick={auth.signinRedirect}
              >
                <span>MyID Login</span>
              </Button>
            </Link>
          </div>
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}
