import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogTitle,
  TextField,
  IconButton,
  Tooltip,
  Zoom,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  FormHelperText,
  makeStyles,
} from "@material-ui/core";
import "../CloseComplaint/Close_Complaint.scss";
import {
  baseApiURL,
  tableIconsInputScreen,
} from "../../../../Utilities/Utility";
import { tableHeaderStyle } from "../../../../Themes/LightTheme";
import MaterialTable from "material-table";
import { useHistory } from "react-router-dom";
import SnackbarCustom from "../../../../components/Snackbar/Snackbar";
import Loader from "../../../../components/Loader/Loader";
import axios from "axios";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import OnlinePactNavBar from "../OnlinePactNavBar";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1, 1, 0, 0),
  },
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function EscalateConfirmation() {
  const compRowData = useSelector(
    (state) => state.complaintDetailDataReducer.complaintDetailData
  );
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );
  const siteToken = useSelector((state) => state.saveTokenReducer.token);
  const azureToken = useSelector((state) => state.saveAzureTokenReducer);
  const UID = useSelector((state) => state.saveUserIdReducer);

  const history = useHistory();
  const api = axios.create({
    baseURL: "CloseComplaint",
  });
  const EscalateApi = axios.create({
    baseURL: "Escalation",
  });

  useEffect(() => {
    console.log("compRowData", compRowData);
  });

  let saveStatusArgs = {};
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const handleSnackOpen = (text, type) => {
    setSnackMessage(text);
    setAlertType(type);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const tableOptions = {
    headerStyle: tableHeaderStyle,
    showTitle: false,
    draggable: false,
    pageSize: 5,
    pageSizeOptions: [5, 10, 25, 50],
    paginationPosition: "bottom",
    //paging: false,
    maxBodyHeight: "600px",
    sorting: true,
  };
  const closeCompDtlColumn = [
    { title: "Invoice Position", field: "item" },
    { title: "MLFB", field: "mlfb" },
    { title: "Quantity", field: "quantity" },
    { title: "Order No", field: "orderNo" },
    { title: "Order Item", field: "orderItem" },
    { title: "BSS", field: "bss" },
    { title: "BU", field: "bu" },
    { title: "Affected MLFB", field: "enteredMlfb" },
    { title: "Affected Qty", field: "enteredQuantity" },
  ];

  const classes = useStyles();

  const [escalateRemarks, setEscalateRemarks] = useState("");

  const escalateRemarksChange = (e) => {
    setEscalateRemarks(e.target.value);
  };
  const handleStatusSubmit = () => {
    if (escalateRemarks == "" || escalateRemarks == undefined) {
      handleSnackOpen("Enter Escalation Remarks !!", "error");
      return;
    }

    saveStatusArgs = {
      EscalatedBy: userDetails.loginId,
      EscalationRemarks: escalateRemarks,
      ComplaintNumber: compRowData.complaintNumber,
    };

    EscalateApi.post("/EscalateComplaint", saveStatusArgs, {
      headers: {
        Authorization: "Bearer " + siteToken,
        UserEmail: userDetails.email,
        userId: UID.id,
        RoleName: userDetails.roleName,
        Gid: userDetails.gid,
        Roleid: userDetails.roleId,
        AzureToken: azureToken.token
      }
    })
      .then((res) => {
        // setReasonMasterData(res.data);
        console.log("Reason Master", res.data);
        history.goBack();
        // setIsLoading(false);
      })
      .catch((error) => {
        console.log("Error in hitting api at page load.", error);
        // setIsLoading(false);
      });

    console.log("Escalated Remarks", escalateRemarks);
  };

  const handleCancelSubmit = () => {
    console.log("Cancel");
    history.goBack();
  };

  return (
    <>
      <OnlinePactNavBar />
      <div className="paddingBtm borderBtm">
        <DialogTitle id="responsive-dialog-title">
          Complaint Details
        </DialogTitle>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="complaintNumber"
              label="Complaint Number"
              variant="filled"
              type="text"
              value={compRowData.complaintNumber}
              // onChange={}
              id="complaintNumber"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="rollNumber"
              label="Roll Number"
              variant="filled"
              type="text"
              value={compRowData.rollNumber}
              // onChange={}
              id="rollNumber"
            />
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="name"
              label="Name"
              variant="filled"
              type="text"
              value={compRowData.name}
              // onChange={}
              id="name"
            />
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="department"
              label="Department"
              variant="filled"
              type="text"
              value={compRowData.departmentName}
              // onChange={}
              id="department"
            />
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="phoneNumber"
              label="Phone Number"
              variant="filled"
              type="text"
              value={compRowData.phoneNumber}
              // onChange={}
              id="phoneNumber"
            />
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="salesOffice"
              label="Sales Office"
              variant="filled"
              type="text"
              value={compRowData.salesOffice}
              // onChange={}
              id="salesOffice"
            />
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="email"
              label="Email"
              variant="filled"
              type="text"
              value={compRowData.email}
              // onChange={}
              id="email"
            />
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="referenceNumber"
              label="Reference Number"
              variant="filled"
              type="text"
              value={compRowData.referenceName}
              // onChange={}
              id="referenceNumber"
            />
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="invoiceNumber"
              label="Invoice Number"
              variant="filled"
              type="text"
              value={compRowData.invoiceNo}
              // onChange={}
              id="invoiceNumber"
            />
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="reason"
              label="Reason"
              variant="filled"
              type="text"
              value={compRowData.reasonName}
              // onChange={}
              id="reason"
            />
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="dispatchCenter"
              label="Dispatch Center"
              variant="filled"
              type="text"
              value={compRowData.dispatchCenterName}
              // onChange={}
              id="dispatchCenter"
            />
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="customer"
              label="Customer"
              variant="filled"
              type="text"
              value={compRowData.customerName}
              // onChange={}
              id="customer"
            />
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="division"
              label="Division"
              variant="filled"
              type="text"
              value={compRowData.division}
              // onChange={}
              id="division"
            />
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="transporterName"
              label="Transporter Name"
              variant="filled"
              type="text"
              value={compRowData.transporter}
              // onChange={}
              id="transporterName"
            />
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="lrNo"
              label="LR No"
              variant="filled"
              type="text"
              value={compRowData.lrno}
              // onChange={}
              id="lrNo"
            />
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="lrDate"
              label="LR Date"
              variant="filled"
              type="text"
              value={compRowData.lrdate}
              // onChange={}
              id="lrDate"
            />
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="seEmail"
              label="SE Email"
              variant="filled"
              type="text"
              value={compRowData.seemail}
              // onChange={}
              id="seEmail"
            />
          </Grid>

          <Grid item xs={12} sm={8} md={6}>
            <TextField
              className=""
              fullWidth
              disabled
              name="remarks"
              label="Remarks"
              variant="filled"
              type="text"
              multiline="true"
              minRows="4"
              maxRows="4"
              value={compRowData.remarks}
              // onChange={}
              id="remarks"
            />
          </Grid>

          {compRowData.fileUrl != null ? (
            <Grid item xs={12} sm={4} md={3}>
              <div>
                <Button
                  className="pt-button--primary bulk-upload-submit-btn"
                  href={compRowData.fileUrl}
                  color="transparent"
                  target="_blank"
                  download
                >
                  Download Attachment
                </Button>
              </div>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>

        <DialogTitle id="responsive-dialog-title">
          Close Complaint Details
        </DialogTitle>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="acceptance"
              label="Acceptance"
              variant="filled"
              type="text"
              value={compRowData.acceptance}
              id="acceptance"
            />
          </Grid>

          <Grid item xs={12} sm={8} md={6}>
            <TextField
              className=""
              fullWidth
              disabled
              name="closingRemarks"
              label="Closing Remarks"
              variant="filled"
              type="text"
              multiline="true"
              minRows="4"
              maxRows="4"
              value={compRowData.closingRemarks}
              id="closingRemarks"
            />
          </Grid>

          {compRowData.closeComplaintAttachment != null ? (
            <Grid item xs={12} sm={4} md={3}>
              <div>
                <Button
                  className="pt-button--primary bulk-upload-submit-btn"
                  href={compRowData.closeComplaintAttachment}
                  color="transparent"
                  target="_blank"
                  download
                >
                  Download Attachment
                </Button>
              </div>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </div>

      <div className="accordionCont paddingBtm borderBtm">
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>MLFB Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <MaterialTable
              title="User List"
              columns={closeCompDtlColumn}
              data={compRowData.invoiceDetails}
              icons={tableIconsInputScreen}
              //isLoading={isLoader}
              options={tableOptions}
            />
          </AccordionDetails>
        </Accordion>
      </div>

      <div>
        <DialogTitle id="responsive-dialog-title">
          Escalation Details
        </DialogTitle>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={3}>
          <div className="marginLft">
            <h3>Escalated By : {userDetails.firstName}</h3>
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} className="closingRemarksClass">
          <TextField
            className=""
            fullWidth
            name="escalateRemarks"
            label="Escalation Remarks"
            variant="filled"
            type="text"
            multiline="true"
            minRows="4"
            maxRows="4"
            inputProps={{
              maxlength: 500,
            }}
            // InputProps={{ inputProps: { min: "0", step: "0.01" } }}
            // value={inputFieldVal.remarks}
            onChange={escalateRemarksChange}
            // onBlur={}
            id="escalateRemarks"
          />
        </Grid>
        <Grid
          container
          spacing={2}
          className="jc-center d-flex mt-1 buttonContainer marginBtm"
        >
          <Grid item xs={10} sm={4} md={3}>
            <Button
              fullWidth
              onClick={handleStatusSubmit}
              className="pt-button--primary mt-1"
            >
              Save
            </Button>
          </Grid>

          <Grid item xs={10} sm={4} md={3}>
            <Button
              fullWidth
              onClick={handleCancelSubmit}
              className="pt-button--secondary mt-1"
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <SnackbarCustom
        open={open}
        message={snackMessage}
        alertType={alertType}
        handleClose={handleClose}
      />
      {isLoading ? <Loader loaderText="Loading...!" /> : <></>}
    </>
  );
}
