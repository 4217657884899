import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { alpha, makeStyles } from "@material-ui/core/styles";
import {
    AppBar,
    Toolbar,
    IconButton,
    InputBase,
    Tooltip,
    Zoom,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SiemensLogoGreen from "../../assets/images/siemens-logo-petrol.svg";
//import { storeUserDetailsAction } from "../../../Redux/Actions/actions";
import { useDispatch, useSelector } from "react-redux";
import "./Header.scss";
import { HomeOutlined } from "@material-ui/icons";
import AccountCircleSharpIcon from "@material-ui/icons/AccountCircleSharp";
import { useAuth } from "react-oidc-context";
import IntraLogo from "../../assets/images/sie-intranet-black-rgb.svg";
import { useMsal } from
    "@azure/msal-react"
    ;

import { saveAccessTokenAction, saveAzureTokenAction, storeUserDetailsAction } from "../../Redux/Actions/actions";


const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    appStyle: {
        backgroundColor: `var(--white)`,
        color: `var(--dark-blue)`,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        /*display: 'none',*/
        [theme.breakpoints.up("sm")]: {
            display: "block",
        },
    },
    search: {
        display: "none",
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        "&:hover": {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(3),
            width: "auto",
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: `transparent`,
    },
    inputRoot: {
        color: "inherit",
        backgroundColor: "var(--light-sand)",
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "12ch",
            "&:focus": {
                width: "20ch",
            },
        },
        [theme.breakpoints.only("xs")]: {
            width: "2ch",
        },
    },
    sectionDesktop: {
        display: "none",
        [theme.breakpoints.up("md")]: {
            display: "flex",
        },
    },
    sectionMobile: {
        display: "flex",
        [theme.breakpoints.up("md")]: {
            display: "none",
        },
    },
}));

function logoutUser(auth, dispatch) {
    var newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname;
    window.history.pushState({ path: newurl }, "", `/`);
    localStorage.clear();
    sessionStorage.clear();
    caches.keys().then((names) => {
        names.forEach((name) => {
            caches.delete(name);
        });
    });
    // if (auth !== undefined) {
    //   //auth.removeUser();
    //   // // dispatch(saveAccessTokenAction(''));
    //   dispatch(storeUserDetailsAction(""));

    // }
    window.location.href = "/";
}

//The "accountHolderDetails" must be updated with the credentials received from MyID

export default function Header() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { instance } = useMsal();
    let userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );

    const handleLogoutRedirect = () => {
        dispatch(saveAccessTokenAction(""));
        dispatch(saveAzureTokenAction(""));
        dispatch(storeUserDetailsAction(null));
        localStorage.clear();
        sessionStorage.clear();
        instance.logoutRedirect().catch((error) => console.log(error));
    };

    let [appHeaderText] = useState(window.innerWidth > 720 ? "RECO" : "RECO TOOL");

    return (
        <div className={classes.grow}>
            <AppBar position="fixed" className={classes.appStyle}>
                <Toolbar>

                    <Link to="/">
                        <img
                            src={SiemensLogoGreen}
                            alt="Siemens"
                            className="official-logo"
                        />
                    </Link>

                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon className="header-search" />
                        </div>
                        <InputBase
                            placeholder="Search"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{ "aria-label": "search" }}
                        />
                    </div>
                    <div className="titleContainer">
                        <div>
                            <h2>{appHeaderText}</h2>
                        </div>
                        <div className="intraLogoCont">
                            <img src={IntraLogo} alt="Intra Logo" className="intra-logo" />
                        </div>
                    </div>

                    <div className={classes.sectionDesktop}></div>

                    {
                        <>
                            <h4>{userDetails.role}</h4>

                            <div className="userIcon">
                                <AccountCircleSharpIcon />
                            </div>
                            <div className="logged-in-user-name">{userDetails.firstName}</div>

                            <Tooltip
                                title="Home"
                                TransitionComponent={Zoom}
                                enterDelay={100}
                                leaveDelay={100}
                            >
                                <Link to="/">
                                    <IconButton>
                                        <HomeOutlined />
                                    </IconButton>
                                </Link>
                            </Tooltip>

                            <Tooltip
                                title="Logout"
                                TransitionComponent={Zoom}
                                enterDelay={100}
                                leaveDelay={100}

                            >
                                <IconButton
                                    className="header-icon"
                                    onClick={handleLogoutRedirect}
                                >
                                    <ExitToAppIcon />
                                </IconButton>
                            </Tooltip>
                        </>
                    }
                </Toolbar>
            </AppBar>
        </div>
    );
}
