import React from "react";
import { Link } from "react-router-dom";
import Menu from "material-ui-popup-state/HoverMenu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import {
  usePopupState,
  bindHover,
  bindMenu,
} from "material-ui-popup-state/hooks";

export default function OnlinePactCHPConfirmation() {
  const popupState = usePopupState({ variant: "popover", popupId: "demoMenu" });
  return (
    <React.Fragment>
      <Link to="reports">
        <Button
          className="reco-button--online-pact-navbtn"
          {...bindHover(popupState)}
        >
          Report
        </Button>
      </Link>
      {/* <Menu
        {...bindMenu(popupState)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <MenuItem onClick={popupState.close} className="op-list-item">
          <Link to="reports">Create</Link>
        </MenuItem>
        <MenuItem onClick={popupState.close} className="op-list-item">
          <Link to="">Close</Link>
        </MenuItem>
        {/* <MenuItem onClick={popupState.close} className="op-list-item">
          <Link to="/onlinepact/chpconfirmation/chptermination">
            CHP Termination
          </Link>
        </MenuItem> */}
      {/* </Menu> */}
    </React.Fragment>
  );
}
