import React from "react";
import { Link } from "react-router-dom";
import Menu from "material-ui-popup-state/HoverMenu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import {
  usePopupState,
  bindHover,
  bindMenu,
} from "material-ui-popup-state/hooks";
import { useSelector } from "react-redux";
import * as roles from "../../../../Utilities/Utility.js";

export default function OnlinePactCHPTermination() {
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );
  let roleName = userDetails.roleName ? userDetails.roleName : "";
  const popupState = usePopupState({ variant: "popover", popupId: "demoMenu" });
  return (
    // <React.Fragment>
    //   <Link to="DelegateComplaint">
    //   <Button
    //     className="reco-button--online-pact-navbtn"
    //     {...bindHover(popupState)}
    //   >
    //     Delegation
    //   </Button>
    //   </Link>
    // </React.Fragment>

    <>
      {roleName.toLowerCase() == roles.salesTeamRole ||  roleName.toLowerCase() == roles.orderManagementRole ? (
        <></>
      ) : (
        <Button
          className="reco-button--online-pact-navbtn"
          {...bindHover(popupState)}
        >
          Delegation
        </Button>
      )}
      <Menu
        {...bindMenu(popupState)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <MenuItem onClick={popupState.close} className="op-list-item">
          <Link to="/DelegateComplaint">Delegate Complaint</Link>
        </MenuItem>
        <MenuItem onClick={popupState.close} className="op-list-item">
          <Link to="/DelegateUser">Delegate Dispatch Center</Link>
        </MenuItem>
      </Menu>
    </>
  );
}
