import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Grid,
    Button,
    Typography,
    Stepper,
    Step,
    StepLabel,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    DialogTitle,
    TextField,
    IconButton,
    Tooltip,
    Zoom,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormLabel,
    FormHelperText,
    makeStyles,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Dialog,
    DialogContent,
    DialogActions,
} from "@material-ui/core";
import "./Close_Complaint.scss";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { tableHeaderStyle } from "../../../../Themes/LightTheme";
import MaterialTable from "material-table";
import {
    baseApiURL,
    tableIconsInputScreen,
} from "../../../../Utilities/Utility";
import { useHistory } from "react-router-dom";
import SnackbarCustom from "../../../../components/Snackbar/Snackbar";
import Loader from "../../../../components/Loader/Loader";
import axios from "axios";
import OnlinePactNavBar from "../OnlinePactNavBar";
import { closingComDetailsAction } from "../../../../Redux/Actions/actions";
import DownloadIconOutlined from "@material-ui/icons/GetApp";
import { deleteTempAttchments } from "../../../../components/CustomComponents/CustomComponents";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightBold,
    },
    accordioncolor: {
        background: "#D1FFF2",
    },
    accordionMargin: {
        marginTop: "10px",
    },
}));

function getSteps() {
    return ["Complaint Details", "MLFB Details", "Accept/Reject Complaint"];
}

const tableOptions = {
    headerStyle: tableHeaderStyle,
    showTitle: false,
    draggable: false,
    pageSize: 5,
    pageSizeOptions: [5, 10, 25, 50],
    paginationPosition: "bottom",
    //paging: false,
    maxBodyHeight: "600px",
    sorting: true,
};
const closeCompDtlColumn = [
    { title: "Invoice Position", field: "item" },
    { title: "MLFB", field: "mlfb" },
    { title: "Quantity", field: "quantity" },
    { title: "Order No", field: "orderNo" },
    { title: "Order Item", field: "orderItem" },
    { title: "BSS", field: "bss" },
    { title: "BU", field: "bu" },
    { title: "Affected MLFB", field: "enteredMlfb" },
    { title: "Affected Qty", field: "enteredQuantity" },
];

export default function CloseComplaintRoot() {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();
    const dispatch = useDispatch();

    const history = useHistory();
    const api = axios.create({
        baseURL: "CloseComplaint",
    });
    const apiComp = axios.create({
        baseURL: "Complaint",
    });
    let saveStatusArgs = {};
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [tempAttachmentCloseId, setTempAttachmentCloseId] = useState(0);
    const compRowData = useSelector(
        (state) => state.complaintDetailDataReducer.complaintDetailData
    );
    const fileGroupingId = compRowData.fileGroupingId;
    // useEffect(() => {
    //   const fileUrl = compRowData.fileUrl;
    //   console.log("compRowData", fileUrl);
    //   const regexExtn = new RegExp("([^\.]*$)");
    //   const splitURLStr = fileUrl.split("?");
    //   const fileExtn = regexExtn.exec(splitURLStr[1]);
    //   console.log("splitURLStr", fileExtn);
    // }, [compRowData.fileUrl]);

    useEffect(() => {
        console.log("compRowData", compRowData);
        getAttachments();
        //deleteCloseAttachments();
        deleteTempAttchments(userDetails.loginId, siteToken, userDetails, UID, azureToken);
        assignFieldsValue();
    }, []);

    const assignFieldsValue = () => {
        setRadioValue(storedClosingDtls.status);
        setClosingRemarks(storedClosingDtls.remarks);
    };
    const getAttachments = () => {
        let complaintNor = { ComplaintNumber: compRowData.complaintNumber };

        api
            .post("/GetTempAttachmntList", complaintNor, {
                headers: {
                    Authorization: "Bearer " + siteToken,
                    UserEmail: userDetails.email,
                    userId: UID.id,
                    RoleName: userDetails.roleName,
                    Gid: userDetails.gid,
                    Roleid: userDetails.roleId,
                    AzureToken: azureToken.token
                }
            })
            .then((res) => {
                console.log("Attchments", res);
                setFileData(res.data.tempAttachmentDetails);
            })
            .catch((error) => {
                console.log("Error", error);
            });
    };
    const deleteCloseAttachments = () => {
        let groupingID = { FileGroupingId: fileGroupingId };

        api
            .post("/deleteCloseAttachments", groupingID, {
                headers: {
                    Authorization: "Bearer " + siteToken,
                    UserEmail: userDetails.email,
                    userId: UID.id,
                    RoleName: userDetails.roleName,
                    Gid: userDetails.gid,
                    Roleid: userDetails.roleId,
                    AzureToken: azureToken.token
                }
            })
            .then((res) => {
                console.log("Attchments", res);
                setFileData(res.data.tempAttachmentDetails);
            })
            .catch((error) => {
                console.log("Error", error);
            });
    };
    // deleteCloseAttachments
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const siteToken = useSelector((state) => state.saveTokenReducer.token);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    const storedClosingDtls = useSelector(
        (state) => state.closingCompDetailsReducer.complaintDetailData
    );

    const multipleFileColumns = [
        { title: "File Name", field: "filename" },
        {
            title: "Download File",
            width: "10%",
            render: (rowData) => downloadIconButton(rowData),
        },
        { title: "Uploaded By", field: "createdName" }
    ];
    const downloadIconButton = (rowData) => {
        return (
            <Button
                href={rowData.fileUrl}
                color="transparent"
                target="_blank"
                download
            >
                <Tooltip title="Download Attachment">
                    <DownloadIconOutlined />
                </Tooltip>
            </Button>
        );
    };

    const [fileData, setFileData] = useState([]);
    const deleteAttachment = (oldData, resolve, reject) => {
        console.log("Old Data");
        apiComp
            .delete("/deleteAttach/" + oldData.attachmetId, {
                headers: {
                    Authorization: "Bearer " + siteToken,
                    UserEmail: userDetails.email,
                    userId: UID.id,
                    RoleName: userDetails.roleName,
                    Gid: userDetails.gid,
                    Roleid: userDetails.roleId,
                    AzureToken: azureToken.token
                }
            })
            .then((res) => {
                const dataDelete = [...closeAttachments];
                const index = oldData.attachmetId;
                dataDelete.splice(index, 1);
                setCloseAttachments([...dataDelete]);
                reject();
                getFilesFromId(userDetails.loginId);
            })
            .catch((error) => {
                console.log("Error in hitting api at page load.", error);
                handleSnackOpen("Error while deleting file.", "error");
                setIsLoading(false);
                reject();
            });
    };
    const getFilesFromId = (loginId) => {
        apiComp
            .get("/getAttachList/" + loginId, {
                headers: {
                    Authorization: "Bearer " + siteToken,
                    UserEmail: userDetails.email,
                    userId: UID.id,
                    RoleName: userDetails.roleName,
                    Gid: userDetails.gid,
                    Roleid: userDetails.roleId,
                    AzureToken: azureToken.token
                }
            })
            .then((res) => {
                setCloseAttachments(res.data);
                handleSnackOpen("File deleted successfully.", "success");
                setIsLoading(false);
            })
            .catch((error) => {
                console.log("Error in hitting api at page load.", error);
                handleSnackOpen("Error while deleting file.", "error");
                setIsLoading(false);
            });
    };

    const [infoDialogOpen, setInfoDialogOpen] = useState(false);
    const handleInfoDialogClose = (e, reason) => {
        if (reason === "backdropClick") {
            return;
        }
        //setSelectedRowData('');
        setInfoDialogOpen(false);
        history.goBack();
    };

    function getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                return (
                    <div>
                        {/* <DialogTitle id="responsive-dialog-title">
              Complaint Details
            </DialogTitle> */}

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="complaintNumber"
                                    label="Complaint Number"
                                    variant="filled"
                                    type="text"
                                    value={compRowData.complaintNumber}
                                    // onChange={}
                                    id="complaintNumber"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="rollNumber"
                                    label="Roll Number"
                                    variant="filled"
                                    type="text"
                                    value={compRowData.rollNumber}
                                    // onChange={}
                                    id="rollNumber"
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="name"
                                    label="Name"
                                    variant="filled"
                                    type="text"
                                    value={compRowData.name}
                                    // onChange={}
                                    id="name"
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="department"
                                    label="Department"
                                    variant="filled"
                                    type="text"
                                    value={compRowData.departmentName}
                                    // onChange={}
                                    id="department"
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="phoneNumber"
                                    label="Phone Number"
                                    variant="filled"
                                    type="text"
                                    value={compRowData.phoneNumber}
                                    // onChange={}
                                    id="phoneNumber"
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="salesOffice"
                                    label="Sales Office"
                                    variant="filled"
                                    type="text"
                                    value={compRowData.salesOffice}
                                    // onChange={}
                                    id="salesOffice"
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="email"
                                    label="Email"
                                    variant="filled"
                                    type="text"
                                    value={compRowData.email}
                                    // onChange={}
                                    id="email"
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="referenceNumber"
                                    label="Reference Number"
                                    variant="filled"
                                    type="text"
                                    value={compRowData.referenceName}
                                    // onChange={}
                                    id="referenceNumber"
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="invoiceNumber"
                                    label="Invoice Number"
                                    variant="filled"
                                    type="text"
                                    value={compRowData.invoiceNo}
                                    // onChange={}
                                    id="invoiceNumber"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="reason"
                                    label="Reason"
                                    variant="filled"
                                    type="text"
                                    value={compRowData.reasonName}
                                    // onChange={}
                                    id="reason"
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="dispatchCenter"
                                    label="Dispatch Center"
                                    variant="filled"
                                    type="text"
                                    value={compRowData.dispatchCenterName}
                                    // onChange={}
                                    id="dispatchCenter"
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="customer"
                                    label="Customer"
                                    variant="filled"
                                    type="text"
                                    value={compRowData.customerName}
                                    // onChange={}
                                    id="customer"
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="division"
                                    label="Division"
                                    variant="filled"
                                    type="text"
                                    value={compRowData.division}
                                    // onChange={}
                                    id="division"
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="transporterName"
                                    label="Transporter Name"
                                    variant="filled"
                                    type="text"
                                    value={compRowData.transporter}
                                    // onChange={}
                                    id="transporterName"
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="lrNo"
                                    label="LR No"
                                    variant="filled"
                                    type="text"
                                    value={compRowData.lrno}
                                    // onChange={}
                                    id="lrNo"
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="lrDate"
                                    label="LR Date"
                                    variant="filled"
                                    type="text"
                                    value={compRowData.lrdate}
                                    // onChange={}
                                    id="lrDate"
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="seEmail"
                                    label="SE Email"
                                    variant="filled"
                                    type="text"
                                    multiline="true"
                                    minRows="4"
                                    maxRows="4"
                                    value={compRowData.seemail}
                                    // onChange={}
                                    id="seEmail"
                                />
                            </Grid>

                            <Grid item xs={12} sm={8} md={6}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="remarks"
                                    label="Remarks"
                                    variant="filled"
                                    type="text"
                                    multiline="true"
                                    minRows="4"
                                    maxRows="4"
                                    value={compRowData.remarks}
                                    // onChange={}
                                    id="remarks"
                                />
                            </Grid>
                        </Grid>

                        {/* {fileData.length > 0 ? (
              <MaterialTable
                title="Attachments"
                columns={multipleFileColumns}
                data={fileData}
                icons={tableIconsInputScreen}
                //isLoading={isLoader}
                options={{
                  headerStyle: tableHeaderStyle,
                  draggable: false,
                  pageSize: 3,
                  pageSizeOptions: [3, 5, 10, 50],
                  paginationPosition: "bottom",
                  //paging: false,
                  maxBodyHeight: "600px",
                  sorting: true,
                }}
              />
            ) : (
              <></>
            )} */}

                        {fileData.length > 0 ? (
                            <Accordion className={classes.accordionMargin}>
                                <AccordionSummary
                                    className={classes.accordioncolor}
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <h3>Attachments</h3>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <MaterialTable
                                        columns={multipleFileColumns}
                                        data={fileData}
                                        icons={tableIconsInputScreen}
                                        //isLoading={isLoader}
                                        options={{
                                            headerStyle: tableHeaderStyle,
                                            draggable: false,
                                            pageSize: 3,
                                            showTitle: false,
                                            pageSizeOptions: [3, 5, 10, 50],
                                            paginationPosition: "bottom",
                                            //paging: false,
                                            maxBodyHeight: "600px",
                                            sorting: true,
                                        }}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        ) : (
                            <></>
                        )}
                    </div>
                );
            case 1:
                return (
                    <MaterialTable
                        title="User List"
                        columns={closeCompDtlColumn}
                        data={compRowData.invoiceDetails}
                        icons={tableIconsInputScreen}
                        //isLoading={isLoader}
                        options={tableOptions}
                    />
                );
            case 2:
                return (
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4} md={3}>
                                <div className="marginLft">
                                    {/*  <h3>Closed By : {userDetails.firstName}</h3>*/}
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4} md={2} className="acceptCont">
                                <FormControl
                                    component="fieldset"
                                    className={classes.formControl}
                                ></FormControl>
                                <FormLabel component="legend">
                                    Acceptance/Rejection Status:
                                    <RadioGroup
                                        aria-label="quiz"
                                        name="quiz"
                                        value={
                                            radioValue != "" ? radioValue : storedClosingDtls.status
                                        }
                                        onChange={handleRadioChange}
                                    >
                                        <FormControlLabel
                                            value="1"
                                            control={<Radio />}
                                            label="Accept"
                                        />
                                        <FormControlLabel
                                            value="2"
                                            control={<Radio />}
                                            label="Reject"
                                        />
                                    </RadioGroup>
                                </FormLabel>
                            </Grid>

                            <Grid item xs={12} sm={4} md={4} className="closingRemarks">
                                <TextField
                                    className="pt-1"
                                    fullWidth
                                    name="closingRemarks"
                                    label="Closing Remarks"
                                    variant="filled"
                                    type="text"
                                    multiline="true"
                                    minRows="4"
                                    maxRows="4"
                                    inputProps={{
                                        maxlength: 500,
                                    }}
                                    value={
                                        closingRemarks != ""
                                            ? closingRemarks
                                            : storedClosingDtls.remarks
                                    }
                                    onChange={closingRemarksChange}
                                    id="closingRemarks"
                                />
                            </Grid>
                            {/* </Grid>

            <Grid container spacing={2}> */}
                            <Grid item xs={12} sm={4} md={3}>
                                <form className="file-upload-form">
                                    <TextField
                                        type="file"
                                        inputProps={{
                                            multiple: true,
                                        }}
                                        label="Upload File"
                                        variant="filled"
                                        className="file-upload"
                                        name="fileUpload"
                                        onChange={updateExcelHandler}
                                        key={inputFileValue}
                                    />
                                </form>
                            </Grid>
                            <Grid item xs={12} sm={4} md={3}>
                                <Button
                                    className="pt-button--secondary bulk-upload-submit-btn"
                                    type="button"
                                    disabled={selectedFile ? false : true}
                                    onClick={onSubmit}
                                >
                                    Upload
                                </Button>
                                <span className="mandatoryText fileSize">
                                    Maximum size 20mb
                                </span>
                            </Grid>
                        </Grid>

                        {/* Close Complaint Upload */}
                        {closeAttachments.length > 0 ? (
                            <Accordion className={classes.accordionMargin}>
                                <AccordionSummary
                                    className={classes.accordioncolor}
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <h3>Attachments</h3>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <MaterialTable
                                        columns={multipleFileColumns}
                                        data={closeAttachments}
                                        icons={tableIconsInputScreen}
                                        //isLoading={isLoader}
                                        options={{
                                            headerStyle: tableHeaderStyle,
                                            draggable: false,
                                            showTitle: false,
                                            pageSize: 5,
                                            pageSizeOptions: [5, 10, 25, 50],
                                            paginationPosition: "bottom",
                                            //paging: false,
                                            maxBodyHeight: "600px",
                                            sorting: true,
                                        }}
                                        editable={{
                                            onRowDelete: (oldTableData) =>
                                                new Promise((resolve, reject) => {
                                                    deleteAttachment(oldTableData, resolve, reject);
                                                }),
                                        }}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        ) : (
                            <></>
                        )}

                        {/* {closeAttachments.length > 0 ? (
              <MaterialTable
                title="Attachments"
                columns={multipleFileColumns}
                data={closeAttachments}
                icons={tableIconsInputScreen}
                //isLoading={isLoader}
                options={{
                  headerStyle: tableHeaderStyle,
                  draggable: false,
                  pageSize: 5,
                  pageSizeOptions: [5, 10, 25, 50],
                  paginationPosition: "bottom",
                  //paging: false,
                  maxBodyHeight: "600px",
                  sorting: true,
                }}
                editable={{
                  onRowDelete: (oldTableData) =>
                    new Promise((resolve, reject) => {
                      deleteAttachment(oldTableData, resolve, reject);
                    }),
                }}
              />
            ) : (
              <></>
            )} */}
                    </>
                );
            default:
                return "Unknown stepIndex";
        }
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        if (activeStep === 2) {
            dispatch(
                closingComDetailsAction({
                    status: radioValue,
                    remarks: closingRemarks,
                })
            );
        }
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const [radioValue, setRadioValue] = useState("");
    const [closingRemarks, setClosingRemarks] = useState("");

    const handleRadioChange = (event) => {
        setRadioValue(event.target.value);
    };
    const closingRemarksChange = (e) => {
        setClosingRemarks(e.target.value);
    };
    const handleStatusSubmit = () => {
        if (radioValue == "" || radioValue == undefined) {
            handleSnackOpen("Select Status !!", "error");
            return;
        }

        if (closingRemarks == "" || closingRemarks == undefined) {
            handleSnackOpen("Enter Closing Remarks !!", "error");
            return;
        }

        saveStatusArgs = {
            Acceptance: parseInt(radioValue),
            ClosedBy: userDetails.loginId,
            ClosingRemarks: closingRemarks,
            ComplaintNumber: compRowData.complaintNumber,
            TempAttachmentCloseId: tempAttachmentCloseId,
            CloseFileGroupingId: closeFileGroupingID,
            AttachmentTable: closeAttachments
        };
        saveCloseComp(saveStatusArgs);
    };

    function saveCloseComp(saveStatusArgs) {
        setIsLoading(true);
        api
            .post("/SaveCloseComplaint", saveStatusArgs, {
                headers: {
                    Authorization: "Bearer " + siteToken,
                    UserEmail: userDetails.email,
                    userId: UID.id,
                    RoleName: userDetails.roleName,
                    Gid: userDetails.gid,
                    Roleid: userDetails.roleId,
                    AzureToken: azureToken.token
                }
            })
            .then((res) => {
                setIsLoading(false);
                // setReasonMasterData(res.data);
                console.log("Reason Master", res.data);
                // history.goBack();
                setInfoDialogOpen(true);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen("Error in Closing Complaint.", "error");
                console.log("Error in hitting api at page load.", error);
            });
    }
    const [inputFileValue, setInputFileValue] = useState(Date.now);
    const [selectedFile, setSelectedFile] = useState(null);
    const updateExcelHandler = (event) => {
        const chosenFiles = Array.prototype.slice.call(event.target.files);
        setSelectedFile(chosenFiles);
        console.log("SelectedFile", event.target.files);
    };
    const [closeAttachments, setCloseAttachments] = useState([]);
    const onSubmit = () => {
        const formData = new FormData();
        // setIsLoading(true);

        for (let i = 0; i < selectedFile.length; i++) {
            if (selectedFile[i].size <= 20971520) {
                formData.append("Document", selectedFile[i]);
            } else if (
                selectedFile.type == "application/octet-stream" ||
                selectedFile.type == "application/x-msdownload"
            ) {
                setSelectedFile("");
                handleSnackOpen("Invalid File Format.", "error");
                console.log("Invalid File Type", selectedFile.type);
            } else {
                setIsLoading(false);
                setSelectedFile("");
                handleSnackOpen("File Size Exceeded.", "error");
                console.log(
                    "Exceed file size || Invalid File format",
                    selectedFile.size
                );
            }
        }

        if (selectedFile.length > 0) {
            setIsLoading(true);
            uploadCloseCompAttachments(formData);
        } else {
            setIsLoading(false);
            handleSnackOpen("Select files to Upload", "warning");
        }
    };
    const [closeFileGroupingID, setCloseFileGroupingID] = useState(0);
    function uploadCloseCompAttachments(formDataArgs) {
        api
            .post("/UploadComplaintFile/" + userDetails.loginId, formDataArgs, {
                headers: {
                    Authorization: "Bearer " + siteToken,
                    UserEmail: userDetails.email,
                    userId: UID.id,
                    RoleName: userDetails.roleName,
                    Gid: userDetails.gid,
                    Roleid: userDetails.roleId,
                    AzureToken: azureToken.token
                }
            })
            .then(function (response) {
                setCloseFileGroupingID(response.data[0].complaintFileMappingId);
                setIsLoading(false);
                setSelectedFile("");
                setCloseAttachments(response.data);
                // setInputFieldVal((inputFieldVal) => ({
                //   ...inputFieldVal,
                //   complaintFileMappingId: response.data[0].complaintFileMappingId,
                // }));
                setIsLoading(false);
                handleSnackOpen("File Uploaded Successfully.", "success");

                console.log("Motor Details", response.data);
            })
            .catch(function (error) {
                setIsLoading(false);
                handleSnackOpen("Error in saving.", "error");
                console.log(error);
            });
        // setIsFileUploaded(true);
    }
    const handleCancelSubmit = () => {
        console.log("Cancel");
        history.goBack();
    };

    return (
        <>
            <OnlinePactNavBar />
            <div className="stepperCont">
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <div>
                    {activeStep === steps.length ? (
                        <div>
                            <Typography className={classes.instructions}>
                                All steps completed
                            </Typography>
                            <Button onClick={handleReset}>Reset</Button>
                        </div>
                    ) : (
                        <div>
                            <Typography className={classes.instructions}>
                                {getStepContent(activeStep)}
                            </Typography>
                            {/* <div>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.backButton}
              >
                Back
              </Button>
              <Button variant="contained" color="primary" onClick={handleNext}>
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </div> */}
                            <Grid container spacing={2} className="jc-center d-flex mt-1">
                                <Grid item xs={10} sm={4} md={2}>
                                    <Button
                                        fullWidth
                                        // disabled={activeStep === 0}
                                        onClick={
                                            activeStep === 0 ? () => history.goBack() : handleBack
                                        }
                                        className="pt-button--secondary mt-1"
                                    >
                                        Back
                                    </Button>
                                </Grid>
                                {activeStep === steps.length - 1 ? (
                                    <Grid item xs={10} sm={4} md={2}>
                                        <Button
                                            fullWidth
                                            onClick={handleStatusSubmit}
                                            className="pt-button--primary mt-1"
                                        >
                                            Save
                                        </Button>
                                    </Grid>
                                ) : (
                                    <Grid item xs={10} sm={4} md={2}>
                                        <Button
                                            fullWidth
                                            onClick={handleNext}
                                            className="pt-button--primary mt-1"
                                        >
                                            Next
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        </div>
                    )}
                </div>
            </div>

            <Dialog
                fullWidth
                maxWidth="sm"
                open={infoDialogOpen}
                onClose={handleInfoDialogClose}
                aria-labelledby="responsive-dialog-title-info"
                disableEscapeKeyDown
            >
                <DialogContent>
                    <span>Complaint Closed Successsfully.</span>
                </DialogContent>
                <DialogActions>
                    <Grid container spacing={2} className="jc-center d-flex mt-1">
                        <Grid item xs={10} sm={4} md={3}>
                            <Button
                                fullWidth
                                onClick={handleInfoDialogClose}
                                className="pt-button--secondary mt-1"
                            >
                                Close
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>

            <SnackbarCustom
                open={open}
                message={snackMessage}
                alertType={alertType}
                handleClose={handleClose}
            />
            {isLoading ? <Loader loaderText="Loading...!" /> : <></>}
        </>
    );
}
