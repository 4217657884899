import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import MaterialTable from "material-table";
import { tableIcons } from "../../../Utilities/Utility";
import { useDispatch, useSelector } from "react-redux";
import {
    Grid,
    MenuItem,
    Select,
    TextField,
    FormControl,
    FormHelperText,
    Tooltip,
    ListItemText,
    Typography,
} from "@material-ui/core";
import "./DispatchCenterMaster.scss";
import SnackbarCustom from "../../../components/Snackbar/Snackbar";
import OnlinePactNavBar from "../../Sales/OnlinePact/OnlinePactNavBar";

export default function DispatchMaster() {
    const api = axios.create({
        baseURL: "UserManagement",
    });
    const apiDispatch = axios.create({
        baseURL: "DispatchCenter",
    });
    const delegateAPI = axios.create({
        baseURL: "Delegate",
    });

    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const siteToken = useSelector((state) => state.saveTokenReducer.token);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);

    //For retriving data
    const [data, setData] = useState([]);

    //for snackBar
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");

    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const handleClose = (e) => {
        setOpen(false);
    };

    //Representing table headers
    var columns = [
        { title: "id", field: "id", hidden: true },
        {
            title: "Dispatch Center Name",
            field: "dispatchCentreName",
            validate: (rowData) =>
                validateRequiredField(
                    rowData,
                    rowData.dispatchCentreName,
                    "dispatchCentreName"
                ),
        },
        {
            title: "Email Id",
            field: "email",
            validate: (rowData) =>
                validateRequiredField(rowData, rowData.email, "email"),
        },
        {
            title: "Superior Email",
            field: "superiorMail",
            validate: (rowData) =>
                validateRequiredField(rowData, rowData.superiorMail, "superiorMail"),
        },
        {
            title: "User Name",
            field: "userId",
            render: (rowData) => <span>{renderRoleValue(rowData)}</span>,
            editComponent: (props) => getUserIDComponent(props),
            validate: (rowData) =>
                validateRequiredField(rowData, rowData.userId, "userId"),
            customFilterAndSearch: (term, rowData) =>
                renderRoleValue(rowData.id).toLowerCase().includes(term.toLowerCase()),
        },
    ];

    function getUserIDComponent(objProps) {
        return (
            <FormControl error={!objProps.value ? true : false} fullWidth>
                <Select
                    value={objProps.value}
                    onChange={(e) => {
                        objProps.onChange(e.target.value);
                    }}
                >
                    {allUsers.map((item, index) => (
                        <MenuItem key={index} value={item.id}>
                            {item.firstName + " " + item.lastName}
                        </MenuItem>
                    ))}
                </Select>
                <FormHelperText>{!objProps.value ? "*Required" : ""}</FormHelperText>
            </FormControl>
        );
    }

    // get list of users from api
    function renderRoleValue(value) {
        let obj = {};

        if (allUsers && allUsers.length > 0) {
            obj = allUsers.find((c) => c.id == value.userId);
        }
        if (obj && obj.firstName != null) {
            return obj.firstName + " " + obj.lastName;
        } else {
            return "";
        }
    }

    useEffect(() => {
        getUsers();
        getDelegateUsers();
    }, []);

    const [allUsers, setAllUsers] = useState([]);
    const getDelegateUsers = () => {
        delegateAPI
            .get("/UsersDelegate", {
                headers: {
                    Authorization: "Bearer " + siteToken,
                    UserEmail: userDetails.email,
                    userId: UID.id,
                    RoleName: userDetails.roleName,
                    Gid: userDetails.gid,
                    Roleid: userDetails.roleId,
                    AzureToken: azureToken.token
                }
            })
            .then((res) => {
                console.log("Delegate User", res);
                setAllUsers(res.data);
            })
            .catch((err) => {
                console.log("Delegate Error", err);
            });
    };
    const getUsers = () => {
        apiDispatch
            .get("/getAllDispatchCenters", {
                headers: {
                    Authorization: "Bearer " + siteToken,
                    UserEmail: userDetails.email,
                    userId: UID.id,
                    RoleName: userDetails.roleName,
                    Gid: userDetails.gid,
                    Roleid: userDetails.roleId,
                    AzureToken: azureToken.token
                }
            })
            .then((res) => {
                setData(res.data);
                console.log("Data", res.data);
            })
            .catch((error) => {
                console.log("Error in hitting api at page load.", error);
            });
    };

    //Add New User
    const handleRowAdd = (newData, resolve, reject) => {
        //newData.roleId = 1;
        newData.createdBy = userDetails.id;
        apiDispatch
            .post("/postDispatchCenter", newData, {
                headers: {
                    Authorization: "Bearer " + siteToken,
                    UserEmail: userDetails.email,
                    userId: UID.id,
                    RoleName: userDetails.roleName,
                    Gid: userDetails.gid,
                    Roleid: userDetails.roleId,
                    AzureToken: azureToken.token
                }
            })
            .then((res) => {
                let dataToAdd = [...data];
                if (res.data === 2) {
                    setData([...dataToAdd]);
                    handleSnackOpen("Dispatch Centre already exist.", "warning");
                    reject();
                } else {
                    dataToAdd.push(newData);
                    setData(dataToAdd);
                    resolve();
                    getUsers();
                    handleSnackOpen("Dispatch Centre added successfully.", "success");
                }
            })
            .catch((error) => {
                reject();
            });
    };

    function validateRequiredField(rowData, value, fieldName) {
        let result = true;
        let requiredVal = true;
        let helperTextVal;
        const expUserID = /^\d+$/;
        const expEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

        if (fieldName === "dispatchCentreName") {
            requiredVal = !value ? false : true;
            helperTextVal = "Invalid Dispatch Centre Name";
            if (value) {
                if (value.length <= 0) {
                    helperTextVal = "Enter Dispatch Centre Name";
                }
            }
        } else if (fieldName === "email" || fieldName === "superiorMail") {
            requiredVal = !value ? false : true;
            result = expEmail.test(value);
            helperTextVal = "Invalid Emailid";
            if (result == false);
            {
                helperTextVal = "Invalid Email Format";
            }
        } else if (fieldName === "userId") {
            requiredVal = !value ? false : true;
            result = expUserID.test(value);
            helperTextVal = "Invalid User";
            if (result == false);
            {
                helperTextVal = "Invalid User";
            }
        }

        return !requiredVal
            ? { isValid: false, helperText: "*Required" }
            : !result
                ? { isValid: false, helperText: helperTextVal }
                : { isValid: true, helperText: "" };
    }

    //Updating the row
    const handleRowUpdate = (newData, oldData, resolve, reject) => {
        newData.ModifiedBy = userDetails.id;
        apiDispatch
            .put("/updateDispatchCenter", newData, {
                headers: {
                    Authorization: "Bearer " + siteToken,
                    UserEmail: userDetails.email,
                    userId: UID.id,
                    RoleName: userDetails.roleName,
                    Gid: userDetails.gid,
                    Roleid: userDetails.roleId,
                    AzureToken: azureToken.token
                }
            })
            .then((res) => {
                const dataUpdate = [...data];
                if (res.data) {
                    const index = oldData.tableData.id;
                    dataUpdate[index] = newData;
                    setData([...dataUpdate]);
                    //resolve();
                    reject();
                    handleSnackOpen("Dispatch Centre updated successfully.", "success");
                }
            })
            .catch((error) => {
                // resolve();
                console.log("Err", error);
                handleSnackOpen("Dispatch Centre updation failed.", "failure");
                reject();
            });
    };

    //Deleting user
    const handleRowDelete = (oldData, resolve, reject) => {
        apiDispatch
            .delete("/deleteDispatchCenter/" + oldData.id, {
                headers: {
                    Authorization: "Bearer " + siteToken,
                    UserEmail: userDetails.email,
                    userId: UID.id,
                    RoleName: userDetails.roleName,
                    Gid: userDetails.gid,
                    Roleid: userDetails.roleId,
                    AzureToken: azureToken.token
                }
            })
            .then((res) => {
                const dataDelete = [...data];
                const index = oldData.tableData.id;
                dataDelete.splice(index, 1);
                setData([...dataDelete]);
                //resolve();
                reject();
                handleSnackOpen("Dispatch Centre deleted successfully.", "success");
            })
            .catch((error) => {
                //resolve();
                reject();
            });
    };

    const tableHeaderStyle = {
        backgroundColor: "var(--light-sand)",
        color: "var(--dark-blue)",
    };
    const tableOptions = {
        headerStyle: tableHeaderStyle,
        showTitle: false,
        draggable: false,
        pageSize: 5,
        pageSizeOptions: [5, 10, 25, 50],
        paginationPosition: "bottom",
        //paging: false,
        maxBodyHeight: "600px",
        // exportButton: true,
        // exportAllData: true
    };

    return (
        <>
            <OnlinePactNavBar />
            <div className="App">
                <Grid container spacing={1}>
                    <Grid item xs={12} className="d-flex jc-space-bt">
                        <Grid item xs={9} className="text-left ml-1">
                            <h2>Dispatch Center Master</h2>
                        </Grid>
                    </Grid>
                    {allUsers.length > 0 ? (
                        <Grid item xs={12}>
                            <MaterialTable
                                title="Dispatch Center List"
                                //title={getTableTitle()}
                                columns={columns}
                                data={data}
                                icons={tableIcons}
                                // isLoading={isLoader}
                                options={tableOptions}
                                editable={{
                                    isEditable: (rowData) => rowData.userId !== 0,
                                    isDeletable: (rowData) => rowData.userId !== 0,
                                    onRowUpdate: (newTableData, oldTableData) =>
                                        new Promise((resolve, reject) => {
                                            handleRowUpdate(
                                                newTableData,
                                                oldTableData,
                                                resolve,
                                                reject
                                            );
                                        }),
                                    onRowAdd: (newTableData) =>
                                        new Promise((resolve, reject) => {
                                            handleRowAdd(newTableData, resolve, reject);
                                        }),
                                    onRowDelete: (oldTableData) =>
                                        new Promise((resolve, reject) => {
                                            handleRowDelete(oldTableData, resolve, reject);
                                        }),
                                }}
                            />
                        </Grid>
                    ) : (
                        <></>
                    )}
                </Grid>
                <SnackbarCustom
                    open={open}
                    message={snackMessage}
                    alertType={alertType}
                    handleClose={handleClose}
                />
            </div>
        </>
    );
}
