import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Grid,
    Button,
    Typography,
    Stepper,
    Step,
    StepLabel,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    DialogTitle,
    TextField,
    IconButton,
    Tooltip,
    Zoom,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormLabel,
    FormHelperText,
    makeStyles,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@material-ui/core";
import "../CloseComplaint/Close_Complaint.scss";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { tableHeaderStyle } from "../../../../Themes/LightTheme";
import MaterialTable from "material-table";
import {
    baseApiURL,
    tableIconsInputScreen,
} from "../../../../Utilities/Utility";
import { useHistory } from "react-router-dom";
import SnackbarCustom from "../../../../components/Snackbar/Snackbar";
import Loader from "../../../../components/Loader/Loader";
import axios from "axios";
import OnlinePactNavBar from "../OnlinePactNavBar";
import {
    closingComDetailsAction,
    escalateRemarksAction,
} from "../../../../Redux/Actions/actions";
import "../CloseComplaint/Close_Complaint.scss";
import {
    DisplayMultipleAttachment,
    getAttachments,
} from "../../../../components/CustomComponents/CustomComponents";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

function getSteps() {
    return [
        "Complaint Details",
        "MLFB Details",
        "Close Complaint Details",
        "Escalate Complaint",
    ];
}

const tableOptions = {
    headerStyle: tableHeaderStyle,
    showTitle: false,
    draggable: false,
    pageSize: 5,
    pageSizeOptions: [5, 10, 25, 50],
    paginationPosition: "bottom",
    //paging: false,
    maxBodyHeight: "600px",
    sorting: true,
};
const closeCompDtlColumn = [
    { title: "Invoice Position", field: "item" },
    { title: "MLFB", field: "mlfb" },
    { title: "Quantity", field: "quantity" },
    { title: "Order No", field: "orderNo" },
    { title: "Order Item", field: "orderItem" },
    { title: "BSS", field: "bss" },
    { title: "BU", field: "bu" },
    { title: "Affected MLFB", field: "enteredMlfb" },
    { title: "Affected Qty", field: "enteredQuantity" },
];

export default function EscalateComplaintRoot() {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();
    const dispatch = useDispatch();

    const history = useHistory();
    const api = axios.create({
        baseURL: "CloseComplaint",
    });
    const EscalateApi = axios.create({
        baseURL: "Escalation",
    });

    let saveStatusArgs = {};
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [tempAttachmentCloseId, setTempAttachmentCloseId] = useState(0);
    const compRowData = useSelector(
        (state) => state.complaintDetailDataReducer.complaintDetailData
    );
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const siteToken = useSelector((state) => state.saveTokenReducer.token);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);

    const storedEscRem = useSelector(
        (state) => state.escalateRemarksReducer.escalateRemarks.remarks
    );

    useEffect(() => {
        getAttachmentsFromID();
    }, []);

    const [createCompAttachments, setCreateCompAttachments] = useState([]);
    const [closeCompAttachments, setCloseCompAttachments] = useState([]);
    async function getAttachmentsFromID() {
        let complaintNor = { ComplaintNumber: compRowData.complaintNumber };
        const response = await getAttachments(complaintNor, siteToken, userDetails, UID, azureToken);
        if (response.status == 200) {
            setCreateCompAttachments(response.data.tempAttachmentDetails);
            setCloseCompAttachments(response.data.closeAttachmentDetails);
        } else {
            handleSnackOpen("Error in fetching Data !!", "error");
        }
    }

    function getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                return (
                    <>
                        {/* <DialogTitle id="responsive-dialog-title">
              Complaint Details
            </DialogTitle> */}

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="complaintNumber"
                                    label="Complaint Number"
                                    variant="filled"
                                    type="text"
                                    value={compRowData.complaintNumber}
                                    // onChange={}
                                    id="complaintNumber"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="rollNumber"
                                    label="Roll Number"
                                    variant="filled"
                                    type="text"
                                    value={compRowData.rollNumber}
                                    // onChange={}
                                    id="rollNumber"
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="name"
                                    label="Name"
                                    variant="filled"
                                    type="text"
                                    value={compRowData.name}
                                    // onChange={}
                                    id="name"
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="department"
                                    label="Department"
                                    variant="filled"
                                    type="text"
                                    value={compRowData.departmentName}
                                    // onChange={}
                                    id="department"
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="phoneNumber"
                                    label="Phone Number"
                                    variant="filled"
                                    type="text"
                                    value={compRowData.phoneNumber}
                                    // onChange={}
                                    id="phoneNumber"
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="salesOffice"
                                    label="Sales Office"
                                    variant="filled"
                                    type="text"
                                    value={compRowData.salesOffice}
                                    // onChange={}
                                    id="salesOffice"
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="email"
                                    label="Email"
                                    variant="filled"
                                    type="text"
                                    value={compRowData.email}
                                    // onChange={}
                                    id="email"
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="referenceNumber"
                                    label="Reference Number"
                                    variant="filled"
                                    type="text"
                                    value={compRowData.referenceName}
                                    // onChange={}
                                    id="referenceNumber"
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="invoiceNumber"
                                    label="Invoice Number"
                                    variant="filled"
                                    type="text"
                                    value={compRowData.invoiceNo}
                                    // onChange={}
                                    id="invoiceNumber"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="reason"
                                    label="Reason"
                                    variant="filled"
                                    type="text"
                                    value={compRowData.reasonName}
                                    // onChange={}
                                    id="reason"
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="dispatchCenter"
                                    label="Dispatch Center"
                                    variant="filled"
                                    type="text"
                                    value={compRowData.dispatchCenterName}
                                    // onChange={}
                                    id="dispatchCenter"
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="customer"
                                    label="Customer"
                                    variant="filled"
                                    type="text"
                                    value={compRowData.customerName}
                                    // onChange={}
                                    id="customer"
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="division"
                                    label="Division"
                                    variant="filled"
                                    type="text"
                                    value={compRowData.division}
                                    // onChange={}
                                    id="division"
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="transporterName"
                                    label="Transporter Name"
                                    variant="filled"
                                    type="text"
                                    value={compRowData.transporter}
                                    // onChange={}
                                    id="transporterName"
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="lrNo"
                                    label="LR No"
                                    variant="filled"
                                    type="text"
                                    value={compRowData.lrno}
                                    // onChange={}
                                    id="lrNo"
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="lrDate"
                                    label="LR Date"
                                    variant="filled"
                                    type="text"
                                    value={compRowData.lrdate}
                                    // onChange={}
                                    id="lrDate"
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="seEmail"
                                    label="SE Email"
                                    variant="filled"
                                    type="text"
                                    multiline="true"
                                    minRows="4"
                                    maxRows="4"
                                    value={compRowData.seemail}
                                    // onChange={}
                                    id="seEmail"
                                />
                            </Grid>

                            <Grid item xs={12} sm={8} md={6}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="remarks"
                                    label="Remarks"
                                    variant="filled"
                                    type="text"
                                    multiline="true"
                                    minRows="4"
                                    maxRows="4"
                                    value={compRowData.remarks}
                                    // onChange={}
                                    id="remarks"
                                />
                            </Grid>

                            {/* {compRowData.fileUrl != null ? (
                <Grid item xs={12} sm={4} md={3}>
                  <div>
                    <Button
                      className="pt-button--primary bulk-upload-submit-btn"
                      href={compRowData.fileUrl}
                      color="transparent"
                      target="_blank"
                      download
                    >
                      Download Attachments
                    </Button>
                  </div>
                </Grid>
              ) : (
                <></>
              )} */}
                        </Grid>
                        {createCompAttachments.length > 0 ? (
                            <DisplayMultipleAttachment tableData={createCompAttachments} />
                        ) : (
                            <></>
                        )}
                    </>
                );
            case 1:
                return (
                    <MaterialTable
                        title="User List"
                        columns={closeCompDtlColumn}
                        data={compRowData.invoiceDetails}
                        icons={tableIconsInputScreen}
                        //isLoading={isLoader}
                        options={tableOptions}
                    />
                );
            case 2:
                return (
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="acceptance"
                                    label="Acceptance"
                                    variant="filled"
                                    type="text"
                                    value={compRowData.acceptance}
                                    id="acceptance"
                                />
                            </Grid>

                            <Grid item xs={12} sm={8} md={6}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="closingRemarks"
                                    label="Closing Remarks"
                                    variant="filled"
                                    type="text"
                                    multiline="true"
                                    minRows="4"
                                    maxRows="4"
                                    value={compRowData.closingRemarks}
                                    id="closingRemarks"
                                />
                            </Grid>

                            {/* {compRowData.closeComplaintAttachment != null ? (
                <Grid item xs={12} sm={4} md={3}>
                  <div>
                    <Button
                      className="pt-button--primary bulk-upload-submit-btn"
                      href={compRowData.closeComplaintAttachment}
                      color="transparent"
                      target="_blank"
                      download
                    >
                      Download Attachment
                    </Button>
                  </div>
                </Grid>
              ) : (
                <></>
              )} */}
                        </Grid>
                        {closeCompAttachments.length > 0 ? (
                            <DisplayMultipleAttachment tableData={closeCompAttachments} />
                        ) : (
                            <></>
                        )}
                    </>
                );
            case 3:
                return (
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4} md={3}>
                                <div className="marginLft">
                                    {/*  <h3>Closed By : {userDetails.firstName}</h3>*/}
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4} md={3}>
                                <div className="marginLft">
                                    <h3>Escalated By : {userDetails.firstName} </h3>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid>
                            <Grid item xs={12}>
                                <div className="marginLft">
                                    <h3>Escalated To: Mamta Mestry</h3>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4} md={4} className="escalationRemarks">
                                <TextField
                                    className="pt-1"
                                    fullWidth
                                    name="escalationRemarks"
                                    label="Escalation Remarks"
                                    variant="filled"
                                    type="text"
                                    multiline="true"
                                    minRows="4"
                                    maxRows="4"
                                    inputProps={{
                                        maxlength: 500,
                                    }}
                                    value={escalateRemarks || storedEscRem}
                                    onChange={escalateRemarksChange}
                                    id="escalationRemarks"
                                />
                            </Grid>
                        </Grid>
                    </>
                );
            default:
                return "Unknown stepIndex";
        }
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        if (activeStep === 2) {
            dispatch(
                escalateRemarksAction({
                    remarks: escalateRemarks,
                })
            );
        }
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const [escalateRemarks, setEscalateRemarks] = useState("");
    const escalateRemarksChange = (e) => {
        setEscalateRemarks(e.target.value);
    };
    const handleStatusSubmit = () => {
        if (escalateRemarks == "" || escalateRemarks == undefined) {
            handleSnackOpen("Enter Escalation Remarks !!", "error");
            return;
        }
        setIsLoading(true);
        saveStatusArgs = {
            EscalatedBy: userDetails.loginId,
            EscalationRemarks: escalateRemarks,
            ComplaintNumber: compRowData.complaintNumber,
        };

        EscalateApi.post("/EscalateComplaint", saveStatusArgs, {
            headers: {
                Authorization: "Bearer " + siteToken,
                UserEmail: userDetails.email,
                userId: UID.id,
                RoleName: userDetails.roleName,
                Gid: userDetails.gid,
                Roleid: userDetails.roleId,
                AzureToken: azureToken.token
            }
        })
            .then((res) => {
                setIsLoading(false);
                handleSnackOpen("Escalated Successfully !!", "success");
                console.log("Reason Master", res.data);
                history.goBack();
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen("Escalation Failed !!", "error");
                console.log("Error in hitting api at page load.", error);
            });

        console.log("Escalation Remarks", escalateRemarks);
    };

    return (
        <>
            <OnlinePactNavBar />
            <div className="stepperCont">
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <div>
                    {activeStep === steps.length ? (
                        <div>
                            <Typography className={classes.instructions}>
                                All steps completed
                            </Typography>
                            <Button onClick={handleReset}>Reset</Button>
                        </div>
                    ) : (
                        <div>
                            <Typography className={classes.instructions}>
                                {getStepContent(activeStep)}
                            </Typography>
                            {/* <div>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.backButton}
              >
                Back
              </Button>
              <Button variant="contained" color="primary" onClick={handleNext}>
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </div> */}
                            <Grid container spacing={2} className="jc-center d-flex mt-1">
                                <Grid item xs={10} sm={4} md={2}>
                                    <Button
                                        fullWidth
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        className="pt-button--secondary mt-1"
                                    >
                                        Back
                                    </Button>
                                </Grid>
                                {activeStep === steps.length - 1 ? (
                                    <Grid item xs={10} sm={4} md={2}>
                                        <Button
                                            fullWidth
                                            onClick={handleStatusSubmit}
                                            className="pt-button--primary mt-1"
                                        >
                                            Save
                                        </Button>
                                    </Grid>
                                ) : (
                                    <Grid item xs={10} sm={4} md={2}>
                                        <Button
                                            fullWidth
                                            onClick={handleNext}
                                            className="pt-button--primary mt-1"
                                        >
                                            Next
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        </div>
                    )}
                </div>
            </div>

            <SnackbarCustom
                open={open}
                message={snackMessage}
                alertType={alertType}
                handleClose={handleClose}
            />
            {isLoading ? <Loader loaderText="Loading...!" /> : <></>}
        </>
    );
}
