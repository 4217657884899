import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogTitle,
  TextField,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import ".././CloseComplaint/Close_Complaint.scss";
import { tableIconsInputScreen } from "../../../../Utilities/Utility";
import { tableHeaderStyle } from "../../../../Themes/LightTheme";
import MaterialTable from "material-table";
import EditIconOutlined from "@material-ui/icons/Edit";
import DownloadIconOutlined from "@material-ui/icons/GetApp";
import Loader from "../../../../components/Loader/Loader";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  closingComDetailsAction,
  complaintDetailDataAction,
} from "../../../../Redux/Actions/actions";
import OnlinePactNavBar from "../OnlinePactNavBar";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SnackbarCustom from "../../../../components/Snackbar/Snackbar";

export default function DelegateUser() {
  let userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );
  const siteToken = useSelector((state) => state.saveTokenReducer.token);
  const azureToken = useSelector((state) => state.saveAzureTokenReducer);
  const UID = useSelector((state) => state.saveUserIdReducer);

  useEffect(() => {
    getDelegateUsers();
    // getDispatchCentreMaster();
    getDispatchCentreData();
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const handleSnackOpen = (text, type) => {
    setSnackMessage(text);
    setAlertType(type);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const delegateAPI = axios.create({
    baseURL: "Delegate",
  });
  const api = axios.create({
    baseURL: "CloseComplaint",
  });
  const [complaintNoSearchStringUI, setComplaintNoSearchStringUI] =
    useState("");
  const [dispatchCenterSearchStringUI, setDispatchCenterSearchStringUI] =
    useState("");
  const [complaintDetailsInput, setComplaintDetailsInput] = useState({
    UserId: "",
    Id: "",
  });
  const dispatchCenterChange = (event, value) => {
    setComplaintDetailsInput((complaintDetailsInput) => ({
      ...complaintDetailsInput,
      Id: value != null ? value.id : value,
    }));
    setDispatchCenterSearchStringUI(value);
  };

  const [delegateUser, setDelegateUser] = useState([]);
  const [delegateUserUI, setDelegateUserUI] = useState([]);
  const getDelegateUsers = () => {
    delegateAPI
      .get("/UsersDelegate", {
        headers: {
          Authorization: "Bearer " + siteToken,
          UserEmail: userDetails.email,
          userId: UID.id,
          RoleName: userDetails.roleName,
          Gid: userDetails.gid,
          Roleid: userDetails.roleId,
          AzureToken: azureToken.token
        }
      })
      .then((res) => {
        console.log("Delegate User", res);
        setDelegateUser(res.data);
      })
      .catch((err) => {
        console.log("Delegate Error", err);
      });
  };
  const delegateUserChange = (e, value) => {
    setDelegateUserUI(value);
    setComplaintDetailsInput((complaintDetailsInput) => ({
      ...complaintDetailsInput,
      UserId: value != null ? value.id : value,
    }));
  };

  // const [dispatchMasterData, setDispatchMasterData] = useState([]);
  // const getDispatchCentreMaster = () => {
  //   api
  //     .get("/getDispatchcentremaster")
  //     .then((res) => {
  //       console.log("Dispatch Master", res.data);
  //       setDispatchMasterData(res.data);
  //     })
  //     .catch((error) => {
  //       console.log("Error in hitting api at page load.", error);
  //     });
  // };

  const [dispatchCenterData, setDispatchCenterData] = useState([]);
  const getDispatchCentreData = () => {
    delegateAPI
      .get("/getAssignedDispatchCentre/" + userDetails.loginId, {
        headers: {
          Authorization: "Bearer " + siteToken,
          UserEmail: userDetails.email,
          userId: UID.id,
          RoleName: userDetails.roleName,
          Gid: userDetails.gid,
          Roleid: userDetails.roleId,
          AzureToken: azureToken.token
        }
      })
      .then((res) => {
        console.log("User Dispatch Data", res.data);
        if (res.data.length > 0) {
          setDispatchCenterData(res.data);
          // setDispatchCenterSearchStringUI(res.data[0]);
          setComplaintDetailsInput((complaintDetailsInput) => ({
            ...complaintDetailsInput,
            Id: res.data[0].id,
          }));
        }
      })
      .catch((error) => {
        console.log("Error in hitting api at page load.", error);
      });
  };

  const handleDelegateDispatch = () => {
    if (
      complaintDetailsInput.UserId == "" ||
      complaintDetailsInput.UserId == null ||
      complaintDetailsInput.Id == "" ||
      complaintDetailsInput.Id == null
    ) {
      handleSnackOpen("All Fields are Mandatory", "error");
    } else {
      setIsLoading(true);
      console.log("Selected", complaintDetailsInput);
      delegateAPI
        .post("/UpdateDispatchCentre", complaintDetailsInput, {
          headers: {
            Authorization: "Bearer " + siteToken,
            UserEmail: userDetails.email,
            userId: UID.id,
            RoleName: userDetails.roleName,
            Gid: userDetails.gid,
            Roleid: userDetails.roleId,
            AzureToken: azureToken.token
          }
        })
        .then((res) => {
          setIsLoading(false);
          console.log("Response", res);
          handleSnackOpen("Delegation is Successfull", "success");
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("Erroe", err);
          handleSnackOpen("Delegation Failed", "error");
        });
    }
  };
  return (
    <>
      <OnlinePactNavBar />

      <DialogTitle id="responsive-dialog-title">
        Delegate Dispatch Center
      </DialogTitle>

      <Grid container spacing={2} className="customerField">
        <Grid item xs={12} sm={4} md={3} className="customerField">
          <Autocomplete
            options={dispatchCenterData}
            getOptionLabel={(option) => option.dispatchCentreName}
            id="debug"
            // disabled
            clearOnEscape={true}
            debug
            value={dispatchCenterSearchStringUI}
            onChange={dispatchCenterChange}
            renderInput={(params) => (
              <TextField {...params} label="Dispatch Center" margin="none" />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
          <Autocomplete
            options={delegateUser}
            getOptionLabel={(option) => {
              if (option.firstName != undefined)
                return `${option.firstName} ${option.lastName}`;
              else return "";
            }}
            id="debug"
            clearOnEscape={true}
            debug
            value={delegateUserUI}
            onChange={delegateUserChange}
            renderInput={(params) => (
              <TextField {...params} label="Delagate User" margin="none" />
            )}
          />
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        className="jc-center d-flex mt-1 buttonContainer marginBtm"
      >
        <Grid item xs={10} sm={4} md={3}>
          <Button
            fullWidth
            onClick={handleDelegateDispatch}
            className="pt-button--primary mt-1"
          >
            Delegate
          </Button>
        </Grid>

        <Grid item xs={10} sm={4} md={3}>
          <Link to="/">
            <Button
              fullWidth
              className="pt-button--secondary mt-1"
            >
              Cancel
            </Button>
          </Link>
        </Grid>
      </Grid>

      <SnackbarCustom
        open={open}
        message={snackMessage}
        alertType={alertType}
        handleClose={handleClose}
      />
      {isLoading ? <Loader loaderText="Loading...!" /> : <></>}
    </>
  );
}
