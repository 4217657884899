import React from "react";
import { Link } from "react-router-dom";
import Menu from "material-ui-popup-state/HoverMenu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import {
  usePopupState,
  bindHover,
  bindMenu,
} from "material-ui-popup-state/hooks";
import { useSelector } from "react-redux";
import * as roles from "../../../../Utilities/Utility.js";

export default function OnlinePactCHPAppointment() {
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );

  let roleName = userDetails.roleName ? userDetails.roleName : "";

  const popupState = usePopupState({ variant: "popover", popupId: "demoMenu" });
  return (
    <React.Fragment>
      {roleName.toLowerCase() !== roles.salesTeamRole ? (
        <Button
          className="reco-button--online-pact-navbtn"
          {...bindHover(popupState)}
        >
          Transactions
        </Button>
      ) : (
        <></>
      )}
      <Menu
        {...bindMenu(popupState)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        {roleName.toLowerCase() == roles.RECOTeamRole ||
        roleName.toLowerCase() == roles.salesTeamRole ? (
          <></>
        ) : (
          <MenuItem onClick={popupState.close} className="op-list-item">
            <Link to="/createcomplaint">Create</Link>
          </MenuItem>
        )}
        {roleName.toLowerCase() == roles.orderManagementRole ||
        roleName.toLowerCase() == roles.salesTeamRole ? (
          <></>
        ) : (
          <MenuItem onClick={popupState.close} className="op-list-item">
            <Link to="/closecomplaint">Close</Link>
          </MenuItem>
        )}

        {roleName.toLowerCase() == roles.orderManagementRole ||
        roleName.toLowerCase() == roles.adminRole ? (
          <MenuItem onClick={popupState.close} className="op-list-item">
            <Link to="/escalatecomplaint">Escalations</Link>
          </MenuItem>
        ) : (
          <></>
        )}
        {/* <MenuItem onClick={popupState.close} className="op-list-item">
                    <Link to="/onlinepact/chpappointment/mandatorydetails">Mandatory Details for CHP Appointment</Link>
                </MenuItem>
                <MenuItem onClick={popupState.close} className="op-list-item">
                    <Link to="/onlinepact/chpappointment/approval">Approval</Link>
                </MenuItem> */}
      </Menu>
    </React.Fragment>
  );
}
